import { SeoPageKeysEnum } from '@alliance/core/seo'

import { HeaderData, HeaderModeEnum, HeaderPositionModeEnum } from '@alliance/shared/header/utils'
import { FooterData } from '@alliance/shared/footer'
import { MobileBarData } from '@alliance/shared/mobile-navigation-bar/utils'

import { log } from '@alliance/shared/logger'
import { LanguageGuard, languageMatcher, languageMatcherDefaultUa } from '@alliance/shared/translation'
import { IsNewRobotaDomainService, UserSideEnum } from '@alliance/shared/utils'

import { Inject, Injectable, NgModule } from '@angular/core'
import { DefaultUrlSerializer, Route, RouterModule, ROUTES, UrlSerializer } from '@angular/router'

import { WINDOW } from '@ng-web-apis/common'

import { AuthorizationUserRedirectGuard } from './guards/authorization-user-redirect.guard'
import { CommonSeoGuard } from './guards/common-seo.guard'
import { EmployerGuard } from './guards/employer.guard'
import { ManagerGuard } from './guards/manager.guard'
import { EmployerDashboardGuard } from './guards/employer-dashboard.guard'
import { GeneralGuard } from './guards/general.guard'
import { TrackPageGuard } from './guards/track-page.guard'
import { CenterTurbotyRedirectGuard } from './guards/center-turboty-redirect.guard'
import { OnlySeekerGuard } from './guards/only-seeker.guard'
import { OnlyLoggedInUsersGuard } from './guards/only-logged-in-user.guard'
import { FeedbackByUserGuard } from './guards/feedback-by-user.guard'
import { AuthRedirectGuard } from './guards/auth-redirect.guard'

import { VacPageRouteMatcher } from './matcher/vac-page-route-matcher'
import { CompanyPageRouteMatcher } from './matcher/company-page-route-matcher'
import { SERVICE_PAGE_ROUTE_MATCHER } from './matcher/services-page-route-matcher'

import { DesktopUrlSerializerService } from './services'

export interface DesktopRoute extends Route {
  data: {
    [key: string]: unknown
    userSide: UserSideEnum
    headerData?: HeaderData
    footerData?: FooterData
    // config for mobile navigation bar
    // add on route, where bar should be by business logic (check for mobile screen is included in component)
    // if route has children, where bar should not be - add this config on below level (e.g., in shell routing level)
    mobileBar?: MobileBarData
    // config for ua banner
    hideChat?: boolean
    seoKey?: SeoPageKeysEnum
  }
  children?: DesktopRoutes | Route[]
}

export type DesktopRoutes = DesktopRoute[]

const baseRoutes: DesktopRoute[] = [
  /* PROZORA ROUTES */
  {
    path: 'prozora',
    loadChildren: (): Promise<unknown> => import('@alliance/prozora/shell').then(m => m.ProzoraShellModule),
    data: {
      userSide: UserSideEnum.jobseeker,
      headerData: { preventRenderHeaderIfMobile: true }
    }
  },
  /* END OF PROZORA ROUTES */

  /* AUTH ROUTES */
  {
    path: 'auth',
    data: {
      userSide: UserSideEnum.all,
      headerData: {
        isShowHeader: false
      },
      footerData: { isShowFooter: false }
    },
    children: [
      {
        path: 'login',
        canActivateChild: [AuthRedirectGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/shared/auth-with-otp/login/shell').then(m => m.SharedAuthWithOtpLoginShellModule)
      },
      {
        path: 'confirm-code',
        loadChildren: (): Promise<unknown> => import('@alliance/shared/auth-with-otp/confirm-code-page/shell').then(m => m.SharedAuthWithOtpConfirmCodePageShellModule)
      },
      {
        path: 'select-account',
        loadChildren: (): Promise<unknown> => import('@alliance/shared/auth-with-otp/select-account-page/shell').then(m => m.SharedAuthWithOtpSelectAccountPageShellModule)
      },
      {
        path: 'registration',
        children: [
          {
            path: '',
            loadChildren: (): Promise<unknown> => import('@alliance/shared/registration/shell').then(m => m.SharedRegistrationShellModule)
          },
          {
            path: 'employer',
            data: {
              userSide: UserSideEnum.employer
            },
            loadChildren: (): Promise<unknown> => import('@alliance/employer/registration/shell').then(m => m.EmployerRegistrationShellModule)
          },
          {
            path: 'jobseeker',
            data: {
              mobileBar: { showForLogged: false, showForUnLogged: false },
              userSide: UserSideEnum.jobseeker
            },
            loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/registration-otp/shell').then(m => m.JobseekerRegistrationOtpShellModule)
          }
        ]
      }
    ]
  },
  {
    path: 'passwordrecovery',
    data: {
      userSide: UserSideEnum.jobseeker,
      headerMode: HeaderModeEnum.innerPage,
      mobileBar: { showForLogged: false, showForUnLogged: false },
      headerData: {
        preventRenderHeaderIfMobile: true
      }
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/profile-settings/feature/change-password-page').then(m => m.JobseekerProfileSettingsFeatureChangePasswordPageModule)
  },
  /* END OF AUTH ROUTES */

  /* EMPLOYER ROUTES */
  {
    path: 'employer',
    canActivateChild: [EmployerDashboardGuard],
    loadChildren: (): Promise<unknown> => import('@alliance/employer/home/shell').then(m => m.EmployerHomeShellModule),
    data: {
      userSide: UserSideEnum.employer,
      headerData: {
        positionMode: HeaderPositionModeEnum.static,
        headerMode: HeaderModeEnum.homePage
      },
      mobileBar: {
        showForLogged: true,
        showForUnLogged: true
      }
    }
  },
  {
    path: 'candidates',
    loadChildren: (): Promise<unknown> => import('@alliance/employer/cvdb/shell-desktop').then(m => m.EmployerCvdbShellDesktopModule),
    data: {
      userSide: UserSideEnum.employer,
      headerData: { preventRenderHeaderIfMobile: true }
    }
  },
  {
    path: 'services',
    data: { userSide: UserSideEnum.employer },
    children: [
      {
        matcher: SERVICE_PAGE_ROUTE_MATCHER,
        loadChildren: (): Promise<unknown> => import('@alliance/employer/services/shell/catalog').then(module => module.EmployerServicesCatalogShellModule),
        data: {
          headerData: { preventRenderHeaderIfMobile: true },
          mobileBar: {
            showForLogged: true,
            showForUnLogged: true
          }
        }
      }
    ]
  },
  {
    path: 'feedback',
    data: {
      userSide: UserSideEnum.all
    },
    runGuardsAndResolvers: 'always',
    canActivate: [FeedbackByUserGuard],
    canActivateChild: [GeneralGuard],
    children: [
      {
        path: 'employer',
        runGuardsAndResolvers: 'always',
        canActivateChild: [GeneralGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/employer/feedback/shell').then(m => m.EmployerFeedbackShellModule),
        data: {
          userSide: UserSideEnum.employer,
          headerData: { preventRenderHeaderIfMobile: true }
        }
      },
      {
        path: 'seeker',
        runGuardsAndResolvers: 'always',
        canActivateChild: [GeneralGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/feedback/shell').then(m => m.JobseekerFeedbackShellModule),
        data: {
          userSide: UserSideEnum.jobseeker,
          headerData: { preventRenderHeaderIfMobile: true }
        }
      }
    ]
  },
  {
    path: 'center-turboty',
    canActivate: [CenterTurbotyRedirectGuard],
    children: [],
    data: { userSide: UserSideEnum.employer }
  },
  /* END OF EMPLOYER ROUTES */

  /* MIXED ROUTES ON /MY PATH */
  {
    path: 'my',
    data: { userSide: UserSideEnum.employer },
    children: [
      /* EMPLOYER ROUTES */
      {
        path: '',
        pathMatch: 'full',
        canActivateChild: [EmployerDashboardGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/employer/dashboard/shell').then(m => m.EmployerDashboardShellModule),
        data: {
          headerData: { positionMode: HeaderPositionModeEnum.static, preventRenderHeaderIfMobile: true },
          mobileBar: {
            showForLogged: true
          }
        }
      },
      {
        path: 'please-confirm-phone',
        canActivate: [EmployerGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/employer/feature-confirm-phone').then(m => m.EmployerFeatureConfirmPhoneModule),
        data: { headerData: { isShowHeader: false }, footerData: { isShowFooter: false } }
      },
      {
        path: 'change-password',
        canActivate: [EmployerGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/employer/change-password/shell').then(m => m.EmployerChangePasswordShellModule),
        data: {
          footerData: { preventRenderFooterIfMobile: true },
          headerData: { positionMode: HeaderPositionModeEnum.static, preventRenderHeaderIfMobile: true }
        }
      },
      {
        path: 'change-email',
        canActivate: [EmployerGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/employer/change-email/shell').then(m => m.EmployerChangeEmailShellModule),
        data: {
          footerData: { preventRenderFooterIfMobile: true },
          headerData: { positionMode: HeaderPositionModeEnum.static, preventRenderHeaderIfMobile: true }
        }
      },
      {
        path: 'turbota',
        // canActivate: [EmployerGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/employer/feature-freemium').then(m => m.EmployerFeatureFreemiumModule),
        data: {
          headerData: { preventRenderHeaderIfMobile: true }
        }
      },
      {
        path: 'vacancies',
        canActivate: [EmployerGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/employer/vacancies/shell').then(m => m.EmployerVacanciesShellModule),
        data: {
          userSide: UserSideEnum.employer,
          headerData: { preventRenderHeaderIfMobile: true },
          mobileBar: { showForLogged: true }
        }
      },
      {
        path: 'services',
        children: [
          {
            path: '',
            canActivate: [EmployerGuard],
            loadChildren: (): Promise<unknown> => import('@alliance/employer/shell-services').then(m => m.EmployerShellServicesModule),
            data: {
              headerData: { preventRenderHeaderIfMobile: true }
            }
          }
        ]
      },
      {
        path: 'users',
        children: [
          {
            path: 'analytics',
            pathMatch: 'full',
            canActivate: [ManagerGuard],
            data: { headerData: { isShowHeader: false } },
            loadChildren: (): Promise<unknown> => import('@alliance/employer/feature-multiuser-dashboard').then(m => m.EmployerFeatureMultiuserDashboardModule)
          },
          {
            path: '',
            canActivate: [EmployerGuard],
            loadChildren: (): Promise<unknown> => import('@alliance/employer/shell-users').then(m => m.EmployerShellUsersModule),
            data: {
              headerData: { preventRenderHeaderIfMobile: true }
            }
          }
        ]
      },
      {
        path: 'log',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'events'
          },
          {
            path: 'events',
            canActivate: [EmployerGuard],
            data: {
              headerData: { preventRenderHeaderIfMobile: true },
              positionMode: HeaderPositionModeEnum.static
            },
            loadChildren: (): Promise<unknown> => import('@alliance/employer/cabinet-events/shell').then(m => m.EmployerCabinetEventsShellModule)
          }
        ]
      },
      {
        path: 'verify-company',
        canActivate: [EmployerGuard],
        data: { headerData: { positionMode: HeaderPositionModeEnum.static } },
        loadChildren: (): Promise<unknown> => import('@alliance/employer/company-verify/shell').then(m => m.EmployerCompanyVerifyShellModule)
      },
      {
        path: 'cart',
        canActivate: [EmployerGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/employer/cart/shell').then(m => m.EmployerCartShellModule),
        data: {
          userSide: UserSideEnum.employer,
          headerData: { isShowHeader: false },
          footerData: { isShowFooter: false },
          hideChat: true
        }
      },
      {
        path: 'chat',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/chat/shell-desktop-app').then(m => m.ChatShellDesktopAppModule),
        data: {
          headerData: { isShowHeader: false },
          footerData: { isShowFooter: false },
          mobileBar: { showForLogged: false, showForUnLogged: false }
        }
      },
      {
        path: 'notification',
        canActivate: [OnlySeekerGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/notifications-center/shell-mobile').then(m => m.JobseekerNotificationsCenterShellMobileModule),
        data: {
          userSide: UserSideEnum.jobseeker,
          headerData: { isShowHeader: false },
          footerData: { isShowFooter: false },
          mobileBar: {
            showForLogged: true,
            showForUnLogged: true
          }
        }
      },
      {
        path: 'settings',
        canActivate: [EmployerGuard],
        data: {
          userSide: UserSideEnum.employer,
          headerData: { preventRenderHeaderIfMobile: true }
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'subscriptions'
          },
          {
            path: 'subscriptions',
            pathMatch: 'full',
            loadChildren: (): Promise<unknown> => import('@alliance/employer/subscriptions-settings/shell').then(m => m.EmployerSubscriptionsSettingsShellModule)
          },
          {
            path: '',
            canActivate: [EmployerGuard],
            loadChildren: (): Promise<unknown> => import('@alliance/employer/shell-profile').then(m => m.EmployerShellProfileModule),
            data: {
              headerData: { preventRenderHeaderIfMobile: true }
            }
          }
        ]
      },
      {
        path: 'wallets',
        canActivate: [EmployerGuard],
        data: {
          headerData: { preventRenderHeaderIfMobile: true }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/employer/wallets/shell').then(m => m.EmployerWalletsShellModule)
      },
      // SUPPORTING OLD URLS FOR personal-data-edit & profile-data-edit FUNCTIONALITY
      {
        path: 'profile-edit',
        pathMatch: 'full',
        redirectTo: '/my/settings/profile'
      },
      {
        path: 'personal-data-edit',
        pathMatch: 'full',
        redirectTo: '/my/settings/personal'
      },
      // SUPPORTING OLD URLS FOR LIMITS FUNCTIONALITY - JUST IN CASE
      {
        path: 'services-limit',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/my/services/limitation'
          },
          {
            path: ':employerId',
            pathMatch: 'full',
            redirectTo: '/my/services/limitation/:employerId'
          }
        ]
      },
      /* END OF EMPLOYER ROUTES */

      /* JOBSEEKER ROUTES */
      {
        path: 'recommendations',
        data: {
          isJobseekerPage: true,
          userSide: UserSideEnum.jobseeker,
          headerData: { preventRenderHeaderIfMobile: true },
          mobileBar: {
            showForLogged: true,
            showForUnLogged: true
          }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/shel-recommended-vacancy-page').then(m => m.JobseekerShelRecommendedVacancyPageModule)
      },
      {
        path: 'favorites/vacancies',
        data: {
          isJobseekerPage: true,
          headerMode: HeaderModeEnum.innerPage,
          userSide: UserSideEnum.jobseeker,
          headerData: { preventRenderHeaderIfMobile: true, positionMode: HeaderPositionModeEnum.static },
          mobileBar: { showForLogged: false, showForUnLogged: false }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/favorites/shell').then(m => m.JobseekerFavoritesShellModule)
      },
      {
        path: 'disliked/vacancies',
        canActivate: [OnlyLoggedInUsersGuard],
        data: {
          userSide: UserSideEnum.jobseeker,
          headerData: { preventRenderHeaderIfMobile: true, positionMode: HeaderPositionModeEnum.static },
          mobileBar: { showForLogged: false, showForUnLogged: false }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/disliked-page/shell').then(m => m.JobseekerDislikedPageShellModule)
      },
      {
        path: 'applies',
        canActivate: [OnlySeekerGuard],
        data: {
          isJobseekerPage: true,
          headerMode: HeaderModeEnum.innerPage,
          userSide: UserSideEnum.jobseeker,
          mobileBar: { showForLogged: false, showForUnLogged: false },
          headerData: { preventRenderHeaderIfMobile: true, positionMode: HeaderPositionModeEnum.static }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/applied-list/shell').then(m => m.JobseekerAppliedListShellModule)
      },
      {
        path: 'applies/:applyId',
        canActivate: [OnlySeekerGuard],
        data: {
          isJobseekerPage: true,
          headerMode: HeaderModeEnum.innerPage,
          userSide: UserSideEnum.jobseeker,
          headerData: { preventRenderHeaderIfMobile: true }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/apply-info-page/shell').then(m => m.JobseekerApplyInfoPageShellModule)
      },
      {
        path: 'resumes',
        canActivate: [OnlySeekerGuard],
        children: [
          {
            path: '',
            canActivate: [OnlySeekerGuard],
            data: {
              isJobseekerPage: true,
              headerMode: HeaderModeEnum.innerPage,
              userSide: UserSideEnum.jobseeker,
              mobileBar: { showForLogged: true, showForUnLogged: true },
              headerData: {
                preventRenderHeaderIfMobile: true
              }
            },
            loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/resumes-list/shell').then(m => m.JobseekerResumesListShellModule)
          },
          {
            path: 'views',
            canActivate: [OnlySeekerGuard],
            data: {
              headerMode: HeaderModeEnum.innerPage,
              userSide: UserSideEnum.jobseeker,
              mobileBar: { showForLogged: false, showForUnLogged: false },
              headerData: {
                preventRenderHeaderIfMobile: true,
                positionMode: HeaderPositionModeEnum.static
              }
            },
            loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/cv-views-page/shell').then(m => m.JobseekerCvViewsPageShellModule)
          },
          {
            path: ':resumeId',
            canActivate: [OnlySeekerGuard],
            data: {
              headerMode: HeaderModeEnum.innerPage,
              userSide: UserSideEnum.jobseeker,
              mobileBar: { showForLogged: false, showForUnLogged: false },
              headerData: {
                preventRenderHeaderIfMobile: true
              }
            },
            loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/cv-builder/shell').then(m => m.JobseekerCvBuilderShellModule)
          },
          {
            path: ':resumeId/recommend',
            data: {
              isJobseekerPage: true,
              headerMode: HeaderModeEnum.innerPage,
              userSide: UserSideEnum.jobseeker,
              headerData: {
                preventRenderHeaderIfMobile: true
              }
            },
            loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/recommended-vacancy-for-resume/shell').then(m => m.JobseekerRecommendedVacancyForResumeShellModule)
          }
        ]
      },
      {
        path: 'subscriptions',
        data: {
          isJobseekerPage: true,
          headerMode: HeaderModeEnum.innerPage,
          userSide: UserSideEnum.jobseeker,
          headerData: { preventRenderHeaderIfMobile: true, positionMode: HeaderPositionModeEnum.static }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/email-subscription/shell').then(m => m.JobseekerEmailSubscriptionShellModule)
      },
      {
        path: 'profile',
        canActivate: [OnlyLoggedInUsersGuard],
        data: {
          isJobseekerPage: true,
          userSide: UserSideEnum.jobseeker,
          headerData: { preventRenderHeaderIfMobile: true, positionMode: HeaderPositionModeEnum.static },
          footerData: { isShowFooter: false },
          mobileBar: { showForLogged: true, showForUnLogged: true }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/profile/user-profile/shell').then(m => m.JobseekerProfileUserProfileShellModule)
      },
      {
        path: 'others',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/profile/others-page/shell').then(m => m.JobseekerProfileOthersPageShellModule),
        data: {
          isJobseekerPage: true,
          userSide: UserSideEnum.jobseeker,
          footerData: { isShowFooter: false },
          headerData: { preventRenderHeaderIfMobile: true },
          mobileBar: { showForLogged: false, showForUnLogged: false }
        }
      },
      {
        path: 'profile-settings',
        canActivate: [OnlyLoggedInUsersGuard],
        data: {
          userSide: UserSideEnum.jobseeker,
          mobileBar: { showForLogged: false, showForUnLogged: false },
          headerData: { preventRenderHeaderIfMobile: true, positionMode: HeaderPositionModeEnum.static }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/profile-settings/shell').then(m => m.JobseekerProfileSettingsShellModule)
      },
      {
        path: 'delete-profile',
        data: {
          userSide: UserSideEnum.jobseeker,
          headerData: { isShowHeader: false },
          footerData: { isShowFooter: false },
          mobileBar: { showForLogged: false, showForUnLogged: false }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/delete-account-page/feature-delete-acc-page').then(m => m.JobseekerDeleteAccountPageFeatureDeleteAccPageModule)
      }
      /* END OF JOBSEEKER ROUTES */
    ]
  },

  /* JOBSEEKER ROUTES */
  {
    path: '',
    pathMatch: 'full',
    canActivateChild: [AuthorizationUserRedirectGuard, EmployerDashboardGuard],
    data: {
      seoKey: SeoPageKeysEnum.home,
      userSide: UserSideEnum.jobseeker,
      headerData: {
        positionMode: HeaderPositionModeEnum.static,
        headerMode: HeaderModeEnum.homePage,
        preventRenderHeaderIfMobile: true
      },
      mobileBar: {
        showForUnLogged: true,
        showForLogged: true
      }
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/home-page/shell').then(m => m.JobseekerHomePageShellModule)
  },
  {
    path: 'mobile-app',
    data: {
      userSide: UserSideEnum.jobseeker,
      headerData: {
        isShowHeader: false
      }
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/shell-apps-page').then(m => m.JobseekerShellAppsPageModule)
  },
  {
    path: 'cv-welcome',
    canActivateChild: [AuthorizationUserRedirectGuard],
    data: {
      seoKey: SeoPageKeysEnum.cvwelcome,
      userSide: UserSideEnum.jobseeker,
      headerData: {
        isShowHeader: false
      }
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/cv-welcome/shell').then(m => m.JobseekerCvWelcomeShellModule)
  },
  {
    path: 'wizard',
    canActivate: [OnlySeekerGuard],
    data: {
      userSide: UserSideEnum.jobseeker,
      headerData: {
        preventRenderHeaderIfMobile: true
      },
      footerData: {
        isShowFooter: false
      },
      mobileBar: { showForLogged: false, showForUnLogged: false }
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/wizzard/shell').then(m => m.JobseekerWizzardShellModule)
  },
  {
    path: 'diia-cv-create',
    canActivate: [OnlySeekerGuard],
    data: {
      userSide: UserSideEnum.jobseeker,
      headerData: {
        isShowHeader: false,
        preventRenderHeaderIfMobile: true
      },
      mobileBar: {
        showForLogged: false,
        showForUnLogged: false
      },
      footerData: { isShowFooter: false },
      hideChat: true
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/cv-create-diia').then(m => m.JobseekerCvCreateDiiaShellModule)
  },
  {
    path: 'city',
    data: {
      userSide: UserSideEnum.jobseeker,
      headerData: {
        isShowHeader: false,
        preventRenderHeaderIfMobile: true
      },
      footerData: { isShowFooter: false }
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/search/shell-mobile-city').then(m => m.JobseekerSearchShellMobileCityModule)
  },
  {
    path: 'keyword',
    data: {
      userSide: UserSideEnum.jobseeker,
      headerData: {
        isShowHeader: false,
        preventRenderHeaderIfMobile: true
      },
      footerData: { isShowFooter: false }
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/search/shell-mobile-keyword').then(m => m.JobseekerSearchShellMobileKeywordModule)
  },
  {
    path: 'subscription/edit',
    canActivate: [OnlySeekerGuard],
    data: {
      userSide: UserSideEnum.jobseeker,
      headerData: {
        isShowHeader: false,
        preventRenderHeaderIfMobile: true
      },
      footerData: { isShowFooter: false }
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/email-subscription/feature-subscription-edit-mobile').then(m => m.JobseekerEmailSubscriptionFeatureSubscriptionEditMobileModule)
  },
  {
    path: 'jobsearch/subscribe_center',
    redirectTo: 'my/subscriptions',
    data: {
      isJobseekerPage: true,
      headerMode: HeaderModeEnum.innerPage,
      userSide: UserSideEnum.jobseeker,
      headerData: { preventRenderHeaderIfMobile: true, positionMode: HeaderPositionModeEnum.static }
    }
  },
  /* JOBSEEKER SEO PAGES ROUTES */
  {
    path: 'jobsearch',
    data: { userSide: UserSideEnum.jobseeker },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/jobsearch/professions'
      },
      {
        path: 'professions',
        data: {
          userSide: UserSideEnum.jobseeker,
          seoKey: SeoPageKeysEnum.searchByProfessionsAlphabet,
          headerData: { preventRenderHeaderIfMobile: true },
          mobileBar: {
            showForLogged: true,
            showForUnLogged: true
          }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/seo-pages/feature-search-by-profession').then(m => m.JobseekerSeoPagesFeatureSearchByProfessionModule)
      },
      {
        path: 'professions-in-cities',
        children: [
          {
            path: '',
            data: {
              userSide: UserSideEnum.jobseeker,
              seoKey: SeoPageKeysEnum.searchByProfessionsCities,
              headerData: { preventRenderHeaderIfMobile: true },
              mobileBar: {
                showForLogged: true,
                showForUnLogged: true
              }
            },
            loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/seo-pages/feature-search-by-profession-cities').then(m => m.JobseekerSeoPagesFeatureSearchByProfessionCitiesModule)
          },
          {
            path: ':cityId',
            data: {
              userSide: UserSideEnum.jobseeker,
              seoKey: SeoPageKeysEnum.searchByProfessionsCity,
              headerData: { preventRenderHeaderIfMobile: true },
              mobileBar: {
                showForLogged: true,
                showForUnLogged: true
              }
            },
            loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/seo-pages/feature-search-by-profession').then(m => m.JobseekerSeoPagesFeatureSearchByProfessionModule)
          }
        ]
      },
      {
        path: 'company',
        data: {
          userSide: UserSideEnum.jobseeker,
          seoKey: SeoPageKeysEnum.searchByCompanyAlphabet,
          headerData: { preventRenderHeaderIfMobile: true },
          mobileBar: {
            showForLogged: true,
            showForUnLogged: true
          }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/seo-pages/feature-search-by-companies').then(m => m.JobseekerSeoPagesFeatureSearchByCompaniesModule)
      },
      {
        path: 'industry',
        data: {
          userSide: UserSideEnum.jobseeker,
          seoKey: SeoPageKeysEnum.searchByCompanyBranch,
          headerData: { preventRenderHeaderIfMobile: true },
          mobileBar: {
            showForLogged: true,
            showForUnLogged: true
          }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/seo-pages/feature-search-by-prof-branches').then(m => m.JobseekerSeoPagesFeatureSearchByProfBranchesModule)
      },
      {
        path: 'rubrics',
        data: {
          userSide: UserSideEnum.jobseeker,
          seoKey: SeoPageKeysEnum.searchByRubrics,
          headerData: { preventRenderHeaderIfMobile: true },
          mobileBar: {
            showForLogged: true,
            showForUnLogged: true
          }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/seo-pages/feature-search-by-rubric').then(m => m.JobseekerSeoPagesFeatureSearchByRubricModule)
      },
      {
        path: 'regions',
        data: {
          userSide: UserSideEnum.jobseeker,
          seoKey: SeoPageKeysEnum.searchByCitiesRegions,
          headerData: { preventRenderHeaderIfMobile: true },
          mobileBar: {
            showForLogged: true,
            showForUnLogged: true
          }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/seo-pages/feature-search-by-regions').then(m => m.JobseekerSeoPagesFeatureSearchByRegionsModule)
      },
      {
        path: 'cities',
        data: {
          userSide: UserSideEnum.jobseeker,
          seoKey: SeoPageKeysEnum.searchByCities,
          headerData: { preventRenderHeaderIfMobile: true },
          mobileBar: {
            showForLogged: true,
            showForUnLogged: true
          }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/seo-pages/feature-search-by-cities').then(m => m.JobseekerSeoPagesFeatureSearchByCitiesModule)
      }
    ]
  },
  {
    path: 'filters',
    data: {
      userSide: UserSideEnum.jobseeker,
      headerData: {
        isShowHeader: false,
        preventRenderHeaderIfMobile: false
      },
      mobileBar: {
        showForLogged: false,
        showForUnLogged: false
      },
      footerData: { isShowFooter: false }
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/vacancies/feature/filters/mobile').then(m => m.JobseekerVacanciesFeatureFiltersMobileModule)
  },
  {
    path: 'zapros',
    data: {
      userSide: UserSideEnum.jobseeker,
      mobileBar: {
        showForLogged: false,
        showForUnLogged: false
      }
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/jobsearch/professions-in-cities'
      },
      {
        path: ':keyWordsUrlSegment/:cityUrlSegment',
        data: {
          userSide: UserSideEnum.jobseeker,
          seoKey: SeoPageKeysEnum.vacancyList,
          headerData: { preventRenderHeaderIfMobile: true }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/vacancies/shell').then(m => m.JobseekerVacanciesShellModule)
      },
      {
        path: ':cityUrlSegment',
        data: {
          userSide: UserSideEnum.jobseeker,
          seoKey: SeoPageKeysEnum.vacancyList,
          headerData: { preventRenderHeaderIfMobile: true }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/vacancies/shell').then(m => m.JobseekerVacanciesShellModule)
      }
    ]
  },
  /* END OF JOBSEEKER SEO PAGES ROUTES */
  {
    matcher: CompanyPageRouteMatcher,
    data: {
      seoKey: SeoPageKeysEnum.company,
      userSide: UserSideEnum.jobseeker,
      headerData: {
        positionMode: HeaderPositionModeEnum.static,
        headerMode: HeaderModeEnum.innerPage,
        preventRenderHeaderIfMobile: true
      }
    },
    loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/company/shell').then(m => m.JobseekerCompanyShellModule)
  },
  {
    matcher: VacPageRouteMatcher,
    data: {
      userSide: UserSideEnum.jobseeker
    },
    children: [
      {
        path: '',
        data: {
          seoKey: SeoPageKeysEnum.vacancy,
          headerData: {
            positionMode: HeaderPositionModeEnum.sticky,
            headerMode: HeaderModeEnum.innerPage,
            preventRenderHeaderIfMobile: true
          }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/vacancy-page/shell').then(m => m.JobseekerVacancyPageShellModule)
      },
      {
        path: 'apply',
        data: {
          userSide: UserSideEnum.jobseeker,
          headerData: {
            isShowHeader: false,
            preventRenderHeaderIfMobile: true
          },
          mobileBar: {
            showForLogged: false,
            showForUnLogged: false
          },
          footerData: { isShowFooter: false },
          hideChat: true
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/apply/shell').then(m => m.JobseekerApplyShellModule)
      },
      {
        path: 'apply/form',
        data: {
          userSide: UserSideEnum.jobseeker,
          headerData: {
            isShowHeader: false,
            preventRenderHeaderIfMobile: true
          },
          mobileBar: {
            showForLogged: false,
            showForUnLogged: false
          },
          footerData: { isShowFooter: false },
          hideChat: true
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/apply/features/apply-form-page').then(m => m.JobseekerApplyFeaturesApplyFormPageModule)
      },
      {
        path: 'thanks-for-apply',
        data: {
          userSide: UserSideEnum.jobseeker,
          headerData: { preventRenderHeaderIfMobile: true }
        },
        loadChildren: (): Promise<unknown> => import('@alliance/jobseeker/shell-thanks-for-apply-page').then(m => m.JobseekerShellThanksForApplyPageModule)
      }
    ]
  },
  {
    path: '404',
    data: {
      headerData: { preventRenderHeaderIfMobile: true },
      userSide: UserSideEnum.jobseeker
    },
    loadChildren: (): Promise<unknown> => import('@alliance/shared/not-found-page/shell').then(m => m.SharedNotFoundPageShellModule)
  },
  {
    path: '**',
    data: {
      headerData: { preventRenderHeaderIfMobile: true },
      userSide: UserSideEnum.jobseeker
    },
    loadChildren: (): Promise<unknown> => import('@alliance/shared/not-found-page/shell').then(m => m.SharedNotFoundPageShellModule)
  }
]

const rootRoute: Route[] = [
  {
    path: '',
    canActivate: [LanguageGuard],
    canActivateChild: [GeneralGuard, CommonSeoGuard, TrackPageGuard],
    children: baseRoutes
  }
]

export const routes: Route[] = [
  {
    matcher: languageMatcher,
    children: rootRoute
  }
]

export const routesDefaultUa: Route[] = [
  {
    matcher: languageMatcherDefaultUa,
    children: rootRoute
  }
]

@Injectable()
@NgModule({
  imports: [
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      relativeLinkResolution: 'legacy'
    })
  ],
  providers: [
    DefaultUrlSerializer,
    {
      provide: UrlSerializer,
      useExisting: DesktopUrlSerializerService
    },
    {
      provide: ROUTES,
      useFactory: (isNewRobotaDomainService: IsNewRobotaDomainService): Route[] => (isNewRobotaDomainService.isNewRobotaDomain() ? routesDefaultUa : routes),
      multi: true,
      deps: [IsNewRobotaDomainService]
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  public constructor(@Inject(WINDOW) private readonly windowRef: Window) {
    /* FE-145
     * на cvdb при возврате с резюме на выдачу со скроллом были проблемы
     * скролл еще до того, как мы перешли на выдачу ресторился браузером
     * в итоге мы на секунду видели середину резюме или футер.
     * DEMO: https://streamable.com/z1juxb
     *
     * Первым решением было поставить `scrollRestoration: top` в роутер-модуле,
     * но в таком случае мы получали проблему с выезжающими панельками (for ex: отклики),
     * которые меняли урлу (добавляли туда id) и соотв. срабатывал скролл наверх.
     * После мы пытались поменять имплементацию `RouterScroller` на свою - https://github.com/angular/angular/blob/5332a4a9191e557d946eb6f3ccc623819462cf0f/packages/router/src/router_scroller.ts#L17
     * но там, увы, все приватное.
     *
     * В итоге пришли к такому решению - просто анфорсить `scrollRestoration` в `manual`.
     * Это решает проблему - `back` ждет возврата на выдачу и только после этого вызывается уже наш `scrollRestoration` из сервиса
     *
     * Каждый браузер выставляет свое дефолтное значение для `history.scrollRestoration` и fun-fact в том, что в `firefox` он по дифолту и так `manual`
     * но корректно начинает работать только после того, как мы засеттили вручную.
     * Magic 🧙 ‍🪄
     * */
    try {
      if (this.windowRef?.history?.scrollRestoration) {
        this.windowRef.history.scrollRestoration = 'manual'
      }
    } catch (e) {
      log.warn({ where: 'desktop', category: 'try_catch', message: 'AppRoutingModule: scrollRestoration unavailable', error: e })
    }
  }
}
