export const StreamTypeMap: Readonly<Record<StreamType, MessageTargetType[]>> = {
  chat: ['message', 'messageupdate'],
  notification: ['notification', 'Crm20Reminder', 'Crm20Event', 'Crm20CompanyRequest'],
  employerStateChanged: ['employeeStateChanged'],
  seekerAddToBlackList: ['userAddedToBlackList'],
  employerScopesChanged: ['employeeScopesChanged'],
  seekerNotification: ['seekerNotification'],
  employerNotification: ['employerNotification'],
  bannerStateChanged: ['bannerStateChanged'],
  bannerEmployerMessage: ['bannerEmployerMessage'],
  employerRegistrationEvent: ['employerRegistrationEvent']
}

export type MessageTargetType =
  | 'message'
  | 'messageupdate'
  | 'notification'
  | 'Crm20Reminder'
  | 'Crm20Event'
  | 'Crm20CompanyRequest'
  | 'employeeScopesChanged'
  | 'employeeStateChanged'
  | 'seekerNotification'
  | 'employerNotification'
  | 'bannerStateChanged'
  | 'bannerEmployerMessage'
  | 'userAddedToBlackList'
  | 'employerRegistrationEvent'

export type StreamType =
  | 'chat'
  | 'notification'
  | 'employerStateChanged'
  | 'employerScopesChanged'
  | 'seekerNotification'
  | 'employerNotification'
  | 'bannerStateChanged'
  | 'bannerEmployerMessage'
  | 'seekerAddToBlackList'
  | 'employerRegistrationEvent'
